import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  Stack,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import { getFullName } from "../utils/user";
import { getAttentionTypeLabel } from "../utils/slot";
import dayjs from "dayjs";
import { capitalize, groupBy } from "lodash";
import { Slot } from "../ts/types";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  gender: string;
  type: string;
  avatar: { key: string };
  doctor: {
    description: string;
  };
}

interface MedicalSpecialty {
  _id: string;
  name: string;
}

interface Institution {
  _id: string;
  name: string;
}

interface DoctorCardProps {
  agenda: {
    // _id: string;
    doctor: User;
    medicalSpecialties: MedicalSpecialty[];
    institution: Institution;
    slots: Slot[];
  };
  agendasLength: number;
  params: {
    [value: string]: string;
  };
}

interface Slots {
  label: string;
  slots: Slot[];
}

type AttentionType = "in-person" | "virtual";

interface FormValues {
  medicalSpecialty: MedicalSpecialty["_id"];
  attentionType: AttentionType;
}

export const DoctorCard: React.FC<DoctorCardProps> = ({
  agenda,
  agendasLength,
  params,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(agendasLength > 2 ? false : true);
  const [slots, setSlots] = useState<Slots[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<Slot | undefined>(undefined);

  const onSubmit = useCallback(
    async (form: FormValues) => {
      navigate(
        `/slot/${selectedSlot?._id}/${form?.medicalSpecialty}/${form?.attentionType}`
      );
    },
    [navigate, selectedSlot]
  );

  const handleSelectSlot = useCallback(
    (slot: Slot) => {
      if (slot.medicalSpecialties.length > 1 || slot.attentionType.length > 1) {
        setSelectedSlot(slot);
      } else {
        navigate(
          `/slot/${slot?._id}/${slot.medicalSpecialties[0]._id}/${slot.attentionType[0]}`
        );
      }
    },
    [navigate]
  );

  const handleClose = useCallback(() => {
    setSelectedSlot(undefined);
  }, []);

  const groupSlots = useCallback((slots: Slot[]) => {
    const groups = groupBy(slots, (slot) => {
      if (dayjs(slot.endsAt).hour() <= 12 && dayjs(slot.endsAt).hour() > 0) {
        return "Mañana";
      } else if (
        dayjs(slot.endsAt).hour() < 18 &&
        dayjs(slot.endsAt).hour() > 12
      ) {
        return "Tarde";
      } else {
        return "Noche";
      }
    });

    return Object.keys(groups).map((key) => ({
      label: key,
      slots: groups[key],
    }));
  }, []);

  useEffect(() => {
    setSlots(groupSlots(agenda.slots));
  }, [agenda.slots, groupSlots]);

  return (
    <>
      <Dialog open={!!selectedSlot} onClose={handleClose} fullWidth>
        <DialogTitle>Seleccionar formato</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction="column"
              gap={4}
              sx={{ padding: 4, minWidth: "50%" }}
            >
              <TextField
                variant="outlined"
                select
                label="Tipo de atención"
                helperText={errors.attentionType?.message}
                {...(errors.attentionType && { error: true })}
                {...register("attentionType", { required: "Campo requerido" })}
                defaultValue={
                  (selectedSlot?.attentionType?.length || 0) === 1
                    ? selectedSlot?.attentionType[0]
                    : undefined
                }
                InputProps={{ disableUnderline: true }}
                sx={{
                  display:
                    (selectedSlot?.attentionType?.length || 0) === 1
                      ? "none"
                      : "flex",
                }}
              >
                {selectedSlot?.attentionType?.map((attentionType, index) => (
                  <MenuItem key={index} value={attentionType}>
                    {getAttentionTypeLabel(attentionType)}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                variant="outlined"
                select
                label="Especialidad médica"
                helperText={errors.medicalSpecialty?.message}
                {...(errors.medicalSpecialty && { error: true })}
                {...register("medicalSpecialty", {
                  required: "Campo requerido",
                })}
                defaultValue={
                  params.medicalSpecialty
                    ? params.medicalSpecialty
                    : (selectedSlot?.medicalSpecialties?.length || 0) === 1
                    ? selectedSlot?.medicalSpecialties[0]._id
                    : undefined
                }
                InputProps={{ disableUnderline: true }}
                sx={{
                  display:
                    (selectedSlot?.medicalSpecialties?.length || 0) === 1
                      ? "none"
                      : "flex",
                }}
              >
                {selectedSlot?.medicalSpecialties?.map(
                  (medicalSpecialty, index) => (
                    <MenuItem key={index} value={medicalSpecialty._id}>
                      {capitalize(medicalSpecialty.name)}
                    </MenuItem>
                  )
                )}
              </TextField>

              <Button variant="contained" type="submit">
                Siguiente
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          borderRadius: 6,
          backgroundColor: "primary.light",
          overflow: "hidden",
          padding: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              gap: 4,
            }}
          >
            <Avatar
              sx={{
                height: 100,
                width: 100,
                borderRadius: 3,
                color: "primary.main",
                backgroundColor: "white",
              }}
              src={`${process.env.REACT_APP_S3_URL}/${agenda.doctor.avatar?.key}`}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: "primary.dark",
                  }}
                >
                  {getFullName(agenda.doctor)}
                </Typography>
                <Typography>
                  {agenda.medicalSpecialties
                    .map((medicalSpecialty) =>
                      capitalize(medicalSpecialty.name)
                    )
                    .join(" - ")}
                </Typography>
              </Box>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <LocationOn sx={{ fontSize: 12 }} />
                  <Typography sx={{ fontSize: 14 }}>
                    {capitalize(agenda.institution.name)}
                  </Typography>
                </Box>
                {/* <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <AccessTime sx={{ fontSize: 12 }} />
                <Typography sx={{ fontSize: 14 }}>
                  Horarios de atención: 09:00 - 18:00
                </Typography>
              </Box> */}
                {agenda.doctor.doctor?.description && (
                  <Typography
                    sx={{ fontSize: 12 }}
                    dangerouslySetInnerHTML={{
                      __html: agenda.doctor.doctor.description,
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: agendasLength > 2 ? "flex" : "none",
              alignItems: "flex-end",
            }}
          >
            <Button variant="contained" onClick={() => setExpanded(!expanded)}>
              {expanded ? "Ocultar" : "Ver"} horarios
            </Button>
          </Box>
        </Box>
        <Collapse
          in={expanded}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ paddingTop: 4 }}>
            <Stack gap={2}>
              {slots.map((time) => (
                <Stack spacing={2} key={time.label}>
                  <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                    {time.label}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 2,
                    }}
                  >
                    {time.slots.map((slot) => (
                      <Button
                        onPointerDown={() => handleSelectSlot(slot)}
                        variant="contained"
                        sx={{
                          paddingX: 6,
                          paddingY: 2,
                        }}
                        key={slot._id}
                        disabled={!slot.available}
                      >
                        <Typography
                          className="elem"
                          sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "primary.dark",
                          }}
                        >
                          {dayjs(slot.startsAt).format("HH:mm")}
                        </Typography>
                      </Button>
                    ))}
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Collapse>
      </Box>
    </>
  );
};
