export type AttentionType = "in-person" | "virtual";

const attentionTypeLabels = {
  "in-person": "Presencial",
  virtual: "Virtual",
};

export const getAttentionTypeLabel = (
  attentionType: AttentionType | undefined
) => (attentionType ? attentionTypeLabels[attentionType] : "N/A");
