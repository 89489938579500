import axios, { AxiosInstance } from "axios";
import { useMemo, useContext } from "react";
import { AuthContext } from "../contexts/AuthContextProvider";

interface AxiosClientParams {
  url?: string;
}

export const useApi = ({ url }: AxiosClientParams = {}): AxiosInstance => {
  const { token } = useContext(AuthContext);

  const api = useMemo(() => {
    return axios.create({
      baseURL: url || process.env.REACT_APP_API_URL || "http://localhost:3000",
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });
  }, [token, url]);

  return api;
};
