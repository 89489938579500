import { useCallback, useContext, useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import dayjs from "dayjs";
import { useApi } from "../hooks/useApi";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Controller } from "react-hook-form";
import { AxiosResponse } from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MuiTelInput } from "mui-tel-input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { AuthContext } from "../contexts/AuthContextProvider";
import { useSnackbar } from "../hooks/useSnackbar";
import { SnackbarModes } from "../contexts/Snackbar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es";
import { capitalize } from "lodash";

interface FormValues {
  email: string;
  emailConfirm: string;
  password: string;
  passwordConfirm: string;
  firstName: string;
  lastName: string;
  governmentIdType: string;
  governmentIdNumber: number;
  gender: string;
  bornAt: Date;
  secondaryPhone: string;
  medicalInsurance: string;
}

interface MedicalInsurance {
  _id: string;
  name: string;
}

export const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
  } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [medicalInsurances, setMedicalInsurances] = useState<
    MedicalInsurance[]
  >([]);
  const { setToken, token, setUser } = useContext(AuthContext);
  const location = useLocation();
  const api = useApi();
  const { showSnackbar } = useSnackbar();

  const getMedicalInsurances = useCallback(async () => {
    try {
      const response: AxiosResponse = await api.get("/medical-insurance");
      if (response?.data) {
        setMedicalInsurances(response.data);
      }
    } catch (err) {}
  }, [api]);

  useEffect(() => {
    getMedicalInsurances();
  }, [getMedicalInsurances]);

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data: FormValues) => {
      setIsLoading(true);
      try {
        const response: AxiosResponse = await api.post("/public/patient", {
          ...data,
          origin: "portal",
        });
        if (response?.data) {
          setToken(response.data.token);
          setUser(response.data.user);
        }
      } catch (err) {
        showSnackbar("Error al registrarse", { mode: SnackbarModes.error });
      } finally {
        setIsLoading(false);
      }
    },
    [setToken, setUser, api, showSnackbar]
  );

  if (token) return <Navigate to={location.state?.redirect || "/"}></Navigate>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography component="h1" variant="h5">
          Registrarse
        </Typography>

        <TextField
          variant="outlined"
          fullWidth
          label="Nombre"
          type="firstName"
          id="firstName"
          autoComplete="firstName"
          helperText={errors.firstName?.message}
          {...(errors.firstName && { error: true })}
          {...register("firstName", { required: "Campo requerido" })}
          InputProps={{ disableUnderline: true }}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Apellido"
          type="lastName"
          id="lastName"
          autoComplete="lastName"
          helperText={errors.lastName?.message}
          {...(errors.lastName && { error: true })}
          {...register("lastName", { required: "Campo requerido" })}
          InputProps={{ disableUnderline: true }}
        />

        <Box sx={{ display: "flex", gap: 4 }}>
          <TextField
            variant="outlined"
            fullWidth
            select
            label="Tipo"
            id="governmentIdType"
            helperText={errors.governmentIdType?.message}
            {...(errors.governmentIdType && { error: true })}
            {...register("governmentIdType", { required: "Campo requerido" })}
            sx={{
              backgroundColor: "white",
            }}
          >
            <MenuItem value="dni">DNI</MenuItem>
          </TextField>
          <TextField
            variant="outlined"
            fullWidth
            label="Nro de documento"
            type="number"
            id="governmentIdNumber"
            autoComplete="governmentIdNumber"
            helperText={errors.governmentIdNumber?.message}
            {...(errors.governmentIdNumber && { error: true })}
            {...register("governmentIdNumber", { required: "Campo requerido" })}
            InputProps={{ disableUnderline: true }}
          />
        </Box>

        <Controller
          name="gender"
          rules={{ required: "Campo requerido" }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl>
              <FormLabel>Género</FormLabel>
              <RadioGroup row onChange={onChange} value={value}>
                <FormControlLabel
                  value="f"
                  control={<Radio />}
                  label="Femenino"
                />
                <FormControlLabel
                  value="m"
                  control={<Radio />}
                  label="Masculino"
                />
                <FormControlLabel value="x" control={<Radio />} label="Otro" />
              </RadioGroup>
              {errors.gender && (
                <FormHelperText error={true}>
                  {errors.gender.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <Controller
            name="bornAt"
            control={control}
            rules={{ required: "Campo requerido" }}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl>
                  <DatePicker
                    label="Fecha de nacimiento"
                    value={value ? dayjs(value) : null}
                    onChange={(newValue) => {
                      onChange(newValue ? newValue.toDate() : null);
                    }}
                  />
                  {errors.bornAt && (
                    <FormHelperText error={true}>
                      {errors.bornAt.message}
                    </FormHelperText>
                  )}
                </FormControl>
              );
            }}
          />
        </LocalizationProvider>

        <Controller
          name="secondaryPhone"
          rules={{ required: "Campo requerido" }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <MuiTelInput
              label="Teléfono"
              value={value}
              onChange={onChange}
              {...(errors.secondaryPhone && { error: true })}
              helperText={errors.secondaryPhone?.message}
            />
          )}
        ></Controller>

        <TextField
          variant="outlined"
          fullWidth
          select
          label="Seguro médico"
          id="medicalInsurance"
          {...register("medicalInsurance")}
          sx={{
            backgroundColor: "white",
          }}
        >
          {medicalInsurances.length ? (
            medicalInsurances.map((medicalInsurance) => (
              <MenuItem value={medicalInsurance._id}>
                {capitalize(medicalInsurance.name)}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="dni">DNI</MenuItem>
          )}
        </TextField>

        <TextField
          variant="outlined"
          fullWidth
          id="email"
          label="Email"
          autoComplete="email"
          helperText={errors.email?.message}
          {...(errors.email && { error: true })}
          {...register("email", {
            required: "Campo requerido",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-x]+\.[A-Z]{2,}$/i,
              message: "Email inválido",
            },
          })}
          InputProps={{ disableUnderline: true }}
        />
        <TextField
          variant="outlined"
          fullWidth
          label="Repetir email"
          id="emailConfirm"
          autoComplete="emailConfirm"
          helperText={errors.emailConfirm?.message}
          {...(errors.emailConfirm && { error: true })}
          {...register("emailConfirm", {
            required: "Campo requerido",
            validate: (value) =>
              value === getValues("email") || "Los campos no coinciden",
          })}
          InputProps={{ disableUnderline: true }}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Contraseña"
          type="password"
          id="password"
          autoComplete="password"
          helperText={errors.password?.message}
          {...(errors.password && { error: true })}
          {...register("password", { required: "Campo requerido" })}
          InputProps={{ disableUnderline: true }}
        />

        <TextField
          variant="outlined"
          fullWidth
          label="Repetir contraseña"
          type="password"
          id="passwordConfirm"
          autoComplete="passwordConfirm"
          helperText={errors.passwordConfirm?.message}
          {...(errors.passwordConfirm && { error: true })}
          {...register("passwordConfirm", { required: "Campo requerido" })}
          InputProps={{ disableUnderline: true }}
        />

        <Button
          type="submit"
          variant="contained"
          sx={{ height: 50, backgroundColor: "primary.dark" }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "white" }} size={16} />
          ) : (
            <Typography sx={{ fontWeight: "bold", color: "white" }}>
              Registrarse
            </Typography>
          )}
        </Button>
      </Box>
    </form>
  );
};
