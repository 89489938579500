import { useEffect, useCallback, useState, useContext, useMemo } from "react";
import { AuthContext } from "../contexts/AuthContextProvider";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { getAttentionTypeLabel } from "../utils/slot";
import { AttentionType } from "../utils/slot";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
}

interface Slot {
  _id: string;
  doctor: User;
  startsAt: Date;
  available: boolean;
  institution: {
    name: string;
  };
  medicalSpecialties: [
    {
      _id: string;
      name: string;
    }
  ];
  attentionType: [AttentionType];
}

export const SlotConfirmation = () => {
  const api = useApi();
  const { user } = useContext(AuthContext);
  let { slotId, medicalSpecialtyId, attentionType } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [slot, setSlot] = useState<Slot>();
  const navigate = useNavigate();

  const getSlot = useCallback(async () => {
    const { data } = await api.get(`/slots/${slotId}`, {
      params: {
        populate: ["doctor", "medicalSpecialties", "institution"],
      },
    });

    setSlot(data);
  }, [api, slotId]);

  const submit = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.post("/appointments", {
        patient: user?._id,
        slot: slot,
        institution: slot?.institution,
        medicalSpecialty: medicalSpecialtyId,
        attentionType,
        origin: "portal",
      });

      setSlot((prevValue) => ({ ...prevValue, available: false } as Slot));
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }, [api, user, slot, attentionType, medicalSpecialtyId]);

  const selectedMedicalSpecialty = useMemo(
    () =>
      capitalize(
        slot?.medicalSpecialties.find((elem) => elem._id === medicalSpecialtyId)
          ?.name
      ),
    [slot, medicalSpecialtyId]
  );

  const selectedAttentionType = useMemo(
    () =>
      getAttentionTypeLabel(
        slot?.attentionType.find((elem) => elem === attentionType)
      ),
    [slot, attentionType]
  );

  useEffect(() => {
    getSlot();
  }, [getSlot]);

  return (
    <Box sx={{ paddingX: "10%", paddingTop: 8 }}>
      {!!slot && (
        <Box
          sx={{
            backgroundColor: "primary.light",
            padding: 8,
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              color: "primary.dark",
              textAlign: { xs: "center", md: "start" },
            }}
          >
            Confirmación de turno
          </Typography>
          <Stack
            spacing={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Stack alignItems="center" direction="column" gap={2}>
              <Typography
                sx={{
                  fontSize: 20,
                }}
              >
                {capitalize(slot.doctor.firstName)}{" "}
                {capitalize(slot.doctor.lastName)}
              </Typography>
              <Typography>
                Especialidad:{" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  {selectedMedicalSpecialty}
                </Typography>
              </Typography>
              <Typography>
                Tipo de atención:{" "}
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  {selectedAttentionType}
                </Typography>
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "primary.dark",
                }}
              >
                {capitalize(slot.institution.name)}
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "primary.dark",
                }}
              >
                {dayjs(slot.startsAt).format("DD/MM - HH:mm")}
              </Typography>
            </Stack>
            {slot.available ? (
              <Box sx={{ display: "flex", gap: 4, justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "error.main" }}
                  disabled={isLoading}
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={submit}
                  variant="contained"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress sx={{ color: "white" }} size={16} />
                  ) : (
                    <Typography>Confirmar</Typography>
                  )}
                </Button>
              </Box>
            ) : (
              <Typography
                sx={{
                  borderRadius: 4,
                  paddingY: 6,
                  paddingX: 12,
                  backgroundColor: "primary.dark",
                  fontSize: 25,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Turno reservado
              </Typography>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};
