import { useCallback, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useApi } from "../hooks/useApi";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { AxiosResponse } from "axios";
import { useSnackbar } from "../hooks/useSnackbar";
import { SnackbarModes } from "../contexts/Snackbar";

type FormValues = {
  email: string;
};

export const Forgot = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const api = useApi();
  const { showSnackbar } = useSnackbar();

  console.log(window.location.origin);

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data: FormValues) => {
      setIsLoading(true);
      try {
        const response: AxiosResponse = await api.put(
          `/users/${data.email}/password-reset`,
          {},
          {
            params: {
              redirectUrl: `${window.location.origin}/auth`,
            },
          }
        );

        if (response.status === 204) {
          setPasswordReset(true);
        }
      } catch (err) {
        showSnackbar("Error al reiniciar contraseña", {
          mode: SnackbarModes.error,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [api, showSnackbar]
  );

  return passwordReset ? (
    <Typography variant="h5">
      Contraseña reiniciada, por favor revisar el correo electrónico
    </Typography>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h5">Reiniciar contraseña</Typography>
        <TextField
          variant="outlined"
          color="primary"
          fullWidth
          id="email"
          placeholder="Email"
          autoComplete="email"
          helperText={errors.email?.message}
          {...(errors.email && { error: true })}
          {...register("email", {
            required: "Campo requerido",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Email inválido",
            },
          })}
          InputProps={{ disableUnderline: true }}
        />

        <Button
          type="submit"
          variant="contained"
          sx={{ height: 50, backgroundColor: "primary.dark" }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "white" }} size={16} />
          ) : (
            <Typography sx={{ fontWeight: "bold", color: "white" }}>
              Reiniciar contraseña
            </Typography>
          )}
        </Button>
      </Box>
    </form>
  );
};
