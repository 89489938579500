import { useState, useEffect } from "react";

export const useIsPwa = () => {
  const [isPwa, setIsPwa] = useState(false);

  useEffect(() => {
    const isRunningAsPwa = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    setIsPwa(isRunningAsPwa);
  }, []);

  return isPwa;
};
