import { capitalize } from "lodash";

type User = {
  firstName: string;
  lastName: string;
  gender: string;
  type: string;
};

export const getFullName = (user: User) => {
  return `${
    user.type === "professional" ? (user.gender === "f" ? "Dra. " : "Dr. ") : ""
  }${capitalize(user.firstName)} ${capitalize(user.lastName)}`;
};
