import { useCallback, useContext, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useApi } from "../hooks/useApi";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { AxiosResponse } from "axios";
import { AuthContext } from "../contexts/AuthContextProvider";
import { useSnackbar } from "../hooks/useSnackbar";
import { SnackbarModes } from "../contexts/Snackbar";

type FormValues = {
  email: string;
  password: string;
};

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState(false);
  const { setToken, token, setUser } = useContext(AuthContext);
  const location = useLocation();
  const api = useApi();
  const { showSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    async (data: FormValues) => {
      setIsLoading(true);
      try {
        const response: AxiosResponse = await api.post("/auth/token", data);
        if (response?.data) {
          setToken(response.data.token);
          setUser(response.data.user);
        }
      } catch (err) {
        showSnackbar("Error al iniciar sesión", { mode: SnackbarModes.error });
      } finally {
        setIsLoading(false);
      }
    },
    [setToken, setUser, api, showSnackbar]
  );

  if (token) return <Navigate to={location.state?.redirect || "/"}></Navigate>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography component="h1" variant="h5">
          Iniciar sesión
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          id="email"
          placeholder="Email"
          autoComplete="email"
          helperText={errors.email?.message}
          {...(errors.email && { error: true })}
          {...register("email", {
            required: "Campo requerido",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Email inválido",
            },
          })}
          InputProps={{ disableUnderline: true }}
        />

        <TextField
          variant="outlined"
          fullWidth
          placeholder="Contraseña"
          type="password"
          id="password"
          autoComplete="password"
          helperText={errors.password?.message}
          {...(errors.password && { error: true })}
          {...register("password", { required: "Campo requerido" })}
          InputProps={{ disableUnderline: true }}
        />

        <Button
          type="submit"
          variant="contained"
          sx={{ height: 50, backgroundColor: "primary.dark" }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "white" }} size={16} />
          ) : (
            <Typography sx={{ fontWeight: "bold", color: "white" }}>
              Iniciar sesión
            </Typography>
          )}
        </Button>
      </Box>
    </form>
  );
};
