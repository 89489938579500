import { useForm, Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
// import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CustomNativeSelect } from "../components/CustomNativeSelect";
import banner from "../assets/images/banner.png";
import { useApi } from "../hooks/useApi";
import { useNavigate, createSearchParams } from "react-router-dom";
import { capitalize } from "lodash";
import { getFullName } from "../utils/user";
// import { MenuItem, TextField } from "@mui/material";

type MedicalSpecialty = {
  _id: string;
  name: string;
};

type Doctor = {
  _id: string;
  firstName: string;
  lastName: string;
  gender: string;
  type: string;
  doctor: {
    description: string;
  };
};

type FormValues = {
  doctor: string;
  medicalSpecialties: MedicalSpecialty["_id"];
};

export const Home = () => {
  const { handleSubmit, control, watch } = useForm<FormValues>();
  const navigate = useNavigate();
  // const theme = useTheme();
  const api = useApi();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [expanded] = useState(true);
  const [, setIsLoading] = useState(false);
  const [medicalSpecialties, setMedicalSpecialties] = useState<
    MedicalSpecialty[]
  >([]);
  const [doctors, setDoctors] = useState<Doctor[]>([]);

  const selectedDoctor = watch("doctor");

  const onSubmit = useCallback(
    (form: FormValues) => {
      const filteredForm = Object.entries(form)
        .filter(([, value]) => !!value)
        .reduce((acc: any, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      navigate({
        pathname: "/slots",
        search: createSearchParams(filteredForm).toString(),
      });
    },
    [navigate]
  );

  const getMedicalSpecialties = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get("/medical-specialty/portal");
      setMedicalSpecialties(data);
      setIsLoading(false);
    } catch (err) {}
  }, [api]);

  const getDoctors = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get("/doctors/portal");

      setDoctors(data);
      setIsLoading(false);
    } catch (err) {}
  }, [api]);

  const doctorDescription = useMemo(() => {
    const selectedDoctorObj = doctors.find(
      (elem) => elem._id === selectedDoctor
    );
    return selectedDoctorObj ? selectedDoctorObj.doctor?.description : "";
  }, [selectedDoctor, doctors]);

  useEffect(() => {
    getMedicalSpecialties();
    getDoctors();
  }, [getMedicalSpecialties, getDoctors]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "end",
          paddingX: 20,
          paddingTop: 10,
          height: 450,
          backgroundColor: "primary.light",
          borderBottomLeftRadius: 50,
          borderBottomRightRadius: 50,
        }}
      >
        <img src={banner} alt="banner" />
      </Box>
      <Box
        sx={{
          borderRadius: 6,
          padding: 7,
          paddingTop: { xs: 20, md: 0 },
          position: { md: "absolute" },
          top: { md: 100 },
          left: { md: "10%" },
          right: { md: "45%" },
          minWidth: "40%",
          zIndex: 1000,
          backgroundColor: "transparent",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Stack gap={6} sx={{ marginBottom: 12 }}>
              <Typography variant="h1">
                Encuentra al especialista ideal y reserva tu turno
              </Typography>
              <Typography variant="h3">
                Explora un vasto universo de expertos médicos listos para
                atenderte
              </Typography>
            </Stack>
            <Stack direction="column" gap={2} sx={{ marginBottom: 4 }}>
              {/* <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search sx={{ color: "white" }} />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                fullWidth
                variant="standard"
                placeholder="Buscar por nombre"
                id="doctor"
                autoComplete="doctor"
                helperText={errors.doctor?.message}
                {...(errors.doctor && { error: true })}
                {...register("doctor", {})}
              /> */}

              <Controller
                name="doctor"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CustomNativeSelect onChange={onChange} value={value}>
                    <option selected disabled>
                      Seleccionar doctor
                    </option>
                    {!!doctors.length &&
                      doctors.map((doctor) => (
                        <option value={doctor._id} key={doctor._id}>
                          {getFullName(doctor)}
                        </option>
                      ))}
                  </CustomNativeSelect>
                )}
              />

              {/* <TextField select variant="outlined" label="Seleccionar doctor">
                {!!doctors.length &&
                  doctors.map((doctor) => (
                    <MenuItem value={doctor._id} key={doctor._id}>
                      {getFullName(doctor)}
                    </MenuItem>
                  ))}
              </TextField> */}

              {/* <Button
                variant="contained"
                onClick={() => setExpanded(!expanded)}
              >
                <Tune sx={{ color: "white" }} />
              </Button> */}
              <Collapse in={expanded}>
                <Stack gap={4} sx={{ marginBottom: 4 }}>
                  <Controller
                    name="medicalSpecialties"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <CustomNativeSelect onChange={onChange} value={value}>
                        <option selected disabled>
                          Seleccionar especialidad
                        </option>
                        {!!medicalSpecialties.length &&
                          medicalSpecialties.map((medicalSpecialty) => (
                            <option
                              value={medicalSpecialty._id}
                              key={medicalSpecialty._id}
                            >
                              {capitalize(medicalSpecialty.name)}
                            </option>
                          ))}
                      </CustomNativeSelect>
                    )}
                  />
                  {/* <CustomNativeSelect>
                  <option value={10}>Osde</option>
                  <option value={20}>Medicus</option>
                  <option value={30}>Swiss Medical</option>
                </CustomNativeSelect>

                <CustomNativeSelect>
                  <option value={10}>Ciudad de Buenos Aires</option>
                  <option value={20}>Mendoza</option>
                  <option value={30}>Córdoba</option>
                </CustomNativeSelect> */}
                </Stack>
                {doctorDescription && (
                  <Typography
                    sx={{
                      fontSize: 12,
                      backgroundColor: "primary.main",
                      color: "primary.light",
                      padding: 4,
                      borderRadius: 2,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: doctorDescription,
                    }}
                  />
                )}
              </Collapse>
            </Stack>

            <Button
              type="submit"
              variant="contained"
              sx={{
                padding: 4,
                borderRadius: 3,
                color: "common.white",
                backgroundColor: "primary.dark",
              }}
            >
              <Typography
                fontSize={15}
                fontWeight="bold"
                textTransform="uppercase"
              >
                Buscar
              </Typography>
            </Button>
          </Stack>
        </form>
      </Box>
      {/* <Stack gap={2}>
        <Typography fontSize={15}>Recomendaciones</Typography>
        <Stack gap={4} direction="column" overflow="scroll">
          <Box sx={{ backgroundColor: "red", borderRadius: 4, padding: 8 }}>
            <Person fontSize="large" />
          </Box>
          <Box sx={{ backgroundColor: "red", borderRadius: 4, padding: 8 }}>
            <Person fontSize="large" />
          </Box>
          <Box sx={{ backgroundColor: "red", borderRadius: 4, padding: 8 }}>
            <Person fontSize="large" />
          </Box>
          <Box sx={{ backgroundColor: "red", borderRadius: 4, padding: 8 }}>
            <Person fontSize="large" />
          </Box>
          <Box sx={{ backgroundColor: "red", borderRadius: 4, padding: 8 }}>
            <Person fontSize="large" />
          </Box>
          <Box sx={{ backgroundColor: "red", borderRadius: 4, padding: 8 }}>
            <Person fontSize="large" />
          </Box>
        </Stack>
      </Stack> */}
    </Box>
  );
};
