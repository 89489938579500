import { useState } from "react";
import { Login } from "./Login";
import { Forgot } from "./Forgot";
import { SignUp } from "./SignUp";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import banner from "../assets/images/bannerAuth.png";
import Image from "mui-image";

export const Auth = () => {
  const [mode, setMode] = useState("login");

  const renderComponent = () => {
    switch (mode) {
      case "signup":
        return (
          <>
            <SignUp />
            <Typography sx={{ textAlign: "center", fontSize: 14 }}>
              ¿Tenés cuenta?{" "}
              <Box
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onPointerDown={() => setMode("login")}
                component="span"
              >
                Iniciá sesión
              </Box>
            </Typography>
          </>
        );

      case "forgot":
        return <Forgot />;

      case "login":
      default:
        return (
          <>
            <Login />
            <Stack gap={2}>
              <Typography sx={{ textAlign: "center", fontSize: 14 }}>
                ¿No tenés cuenta?{" "}
                <Box
                  sx={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onPointerDown={() => setMode("signup")}
                  component="span"
                >
                  Registrate
                </Box>
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 14,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onPointerDown={() => setMode("forgot")}
              >
                ¿Olvidaste tu contraseña?
              </Typography>
            </Stack>
          </>
        );
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { md: "repeat(2, 1fr)" },
        minHeight: "100vh",
        backgroundColor: { md: "primary.light", xs: "white" },
      }}
    >
      <Box
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <Image src={banner} alt="banner" />
      </Box>
      <Box
        gap={4}
        sx={{
          backgroundColor: "white",
          paddingY: 10,
          borderTopLeftRadius: { md: 40 },
          borderBottomLeftRadius: { md: 40 },
          paddingX: { md: "30%", xs: 4 },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 4,
        }}
      >
        {renderComponent()}
      </Box>
    </Box>
  );
};
