import { ReactNode, createContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  SnackbarDefaultOptions,
  SnackbarDefaults,
  SnackbarInterface,
  SnackbarModes,
  SnackbarOptions,
} from "./Snackbar";

export const SnackbarContext =
  createContext<SnackbarInterface>(SnackbarDefaults);

export const SnackbarProvider = ({ children }: { children?: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [options, setOptions] = useState<SnackbarOptions>({
    mode: SnackbarModes.info,
  });
  //   const [action, setAction] = useState(() => {});

  const showSnackbar: SnackbarInterface["showSnackbar"] = (msg, opt) => {
    setMessage(msg);
    setOptions({ ...SnackbarDefaultOptions, ...opt });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        // action={action}
      >
        <Alert severity={options.mode} onClose={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
