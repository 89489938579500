import "dayjs/locale/es";
import { useSearchParams } from "react-router-dom";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { isEqual } from "lodash";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import { useCallback, useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import { DoctorCard } from "../components/DoctorCard";
import { useApi } from "../hooks/useApi";
import { Slot } from "../ts/types";
import { capitalize } from "lodash";
import { useNavigate } from "react-router-dom";
const advancedFormat = require("dayjs/plugin/advancedFormat");
const localeData = require("dayjs/plugin/localeData");
const localizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.locale("es");

interface Day {
  date: Date;
  dayString: string;
  dateString: string;
  agendas: Agenda[];
}

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  gender: string;
  type: string;
  avatar: { key: string };
  doctor: {
    description: string;
  };
}

interface Institution {
  _id: string;
  name: string;
}

interface MedicalSpecialty {
  _id: string;
  name: string;
}

interface Agenda {
  // _id: string;
  doctor: User;
  medicalSpecialties: MedicalSpecialty[];
  institution: Institution;
  slots: Slot[];
}

interface SlotsApiResponse {
  docs: Slot[];
}

interface SearchParams {
  [key: string]: string;
}

export const Slots = () => {
  const api = useApi();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [days, setDays] = useState<Day[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();

  const [searchParams] = useSearchParams();
  const [params] = useState<SearchParams>(
    Object.fromEntries([...searchParams])
  );

  const [isLoading, setIsLoading] = useState(false);
  const [slideDirection, setSlideDirection] = useState<
    "right" | "left" | undefined
  >("left");

  const cardsPerPage = isMobile ? 1 : 7;

  const getFirstAvailableSlot = useCallback(async () => {
    const { data } = await api.get<Slot>("/slots/first", {
      params: {
        ...params,
        startsAt: { $gte: dayjs().toDate() },
      },
    });

    if (data) {
      setSelectedDate(dayjs(data.startsAt).toDate());
    } else {
      setSelectedDate(dayjs().toDate());
    }
  }, [api, params]);

  const groupSlots = useCallback(
    (slots: Slot[], selectedDate: Date) => {
      const days = Array.from({ length: cardsPerPage }, (_, i): Day => {
        const day = dayjs(selectedDate).add(i, "day");

        return {
          date: day.toDate(),
          dayString: day.format("dddd"),
          dateString: day.format("DD/MM"),
          agendas: [],
        };
      });

      return days.map((day: Day) => {
        const matchedSlots = slots.filter((slot: Slot) => {
          return dayjs(slot.startsAt)
            .startOf("day")
            .isSame(dayjs(day.date).startOf("day"));
        });

        const agendas = matchedSlots.reduce(
          (acc: Agenda[], matchedSlot: Slot) => {
            const index = acc.findIndex(
              (elem) =>
                isEqual(elem.institution, matchedSlot.institution) &&
                isEqual(elem.medicalSpecialties, matchedSlot.medicalSpecialties)
            );

            if (index >= 0) {
              acc[index].slots.push(matchedSlot);
            } else {
              acc.push({
                // _id: matchedSlot.agenda._id,
                medicalSpecialties: matchedSlot.medicalSpecialties,
                doctor: matchedSlot.doctor,
                institution: matchedSlot.institution,
                slots: [matchedSlot],
              });
            }

            return acc;
          },
          []
        );

        return { ...day, agendas };
      });
    },
    [cardsPerPage]
  );

  const getSlots = useCallback(
    async (selectedDate: Date) => {
      setIsLoading(true);
      try {
        const startDate = dayjs(selectedDate).startOf("day").toDate();
        const endDate = dayjs(selectedDate)
          .add(cardsPerPage, "days")
          .endOf("day")
          .toDate();

        const { data } = await api.get<SlotsApiResponse>("/slots", {
          params: {
            ...params,
            startsAt: { $gte: startDate, $lt: endDate },
            endsAt: { $lte: endDate, $gt: startDate },
            available: true,
            isVisibleByPatient: true,
            populate: ["doctor medicalSpecialties institution agenda"],
          },
        });

        setDays(groupSlots(data.docs, selectedDate));
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    },
    [api, cardsPerPage, groupSlots, params]
  );

  useEffect(() => {
    getFirstAvailableSlot();
  }, [getFirstAvailableSlot]);

  useEffect(() => {
    if (selectedDate) {
      const day = dayjs(selectedDate).format("dddd");
      const date = dayjs(selectedDate).format("DD/MM");

      if (
        !days.length ||
        !days.some((elem) => elem.dayString === day && elem.dateString === date)
      ) {
        getSlots(selectedDate);
      }
    }
  }, [selectedDate, days, getSlots]);

  const selectedDay = useMemo(
    (): Day | undefined =>
      days.find((day) =>
        dayjs(day.date)
          .startOf("day")
          .isSame(dayjs(selectedDate).startOf("day"))
      ),
    [days, selectedDate]
  );

  const isDaySelected = useCallback(
    (option: Day) => {
      return dayjs(option.date)
        .startOf("day")
        .isSame(dayjs(selectedDate).startOf("day"));
    },
    [selectedDate]
  );

  const handlePrevPage = useCallback(() => {
    setSlideDirection("right");
    setSelectedDate(
      dayjs(days.at(0)?.date).subtract(cardsPerPage, "days").toDate()
    );
  }, [days, cardsPerPage]);

  const handleNextPage = useCallback(() => {
    setSlideDirection("left");
    setSelectedDate(dayjs(days.at(-1)?.date).add(1, "days").toDate());
  }, [days]);

  const handlePrevMonth = useCallback(() => {
    setSelectedDate(
      dayjs(days.at(0)?.date).subtract(1, "month").startOf("month").toDate()
    );
  }, [days]);

  const handleNextMonth = useCallback(() => {
    setSelectedDate(
      dayjs(days.at(-1)?.date).add(1, "month").startOf("month").toDate()
    );
  }, [days]);

  return (
    <Stack gap={4} sx={{ marginTop: 4, paddingX: 6 }}>
      <Stack direction="row" spacing={4}>
        <Typography
          onClick={() => navigate(-1)}
          sx={{
            backgroundColor: "primary.light",
            cursor: "pointer",
            fontSize: 12,
            color: "primary.dark",
            display: "flex",
            alignItems: "center",
            gap: 2,
            paddingX: 2,
            paddingY: 1,
            borderRadius: 10,
          }}
        >
          <NavigateBeforeIcon fontSize="small" />
          Nueva búsqueda
        </Typography>
        {/* {Object.entries(params).map(([key, value]) => (
          <Box
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              fontSize: 12,
              paddingX: 4,
              display: "flex",
              alignItems: "center",
              borderRadius: 4,
            }}
          >
            {capitalize(key)}: {value}
          </Box>
        ))} */}
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        <IconButton onClick={handlePrevMonth}>
          <NavigateBeforeIcon />
        </IconButton>
        <Typography sx={{ fontWeight: "bold", color: "primary.main" }}>
          {capitalize(dayjs(selectedDate).startOf("month").format("MMMM"))}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <NavigateNextIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton onClick={handlePrevPage} sx={{ margin: 5 }}>
          <NavigateBeforeIcon />
        </IconButton>
        <Box>
          <Slide direction={slideDirection} in={true}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                justifyContent: "space-between",
              }}
            >
              {days.map((elem, elemIndex) => (
                <Button
                  variant="contained"
                  onClick={() => setSelectedDate(dayjs(elem.date).toDate())}
                  key={elemIndex}
                  disabled={!elem.agendas.length}
                  sx={{
                    display: "flex",
                    minWidth: 130,
                    flexDirection: "column",
                    backgroundColor: isDaySelected(elem)
                      ? "primary.dark"
                      : "primary.light",
                    padding: 4,
                    borderRadius: 4,
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                      //   "& .label": {
                      //     color: "white",
                      //   },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      color: "primary.main",
                    }}
                    className="label"
                  >
                    {elem.dayString}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "primary.main",
                    }}
                  >
                    {elem.dateString}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Slide>
        </Box>
        <IconButton
          onClick={handleNextPage}
          sx={{
            margin: 5,
          }}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 20 }}>
          <CircularProgress />
        </Box>
      ) : !!selectedDay?.agendas?.length ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              md: selectedDay.agendas?.length > 1 ? "repeat(2, 1fr)" : "1fr",
              xs: "1fr",
            },
            paddingX: { md: "10%", xs: 4 },
            gap: 8,
          }}
        >
          {selectedDay.agendas?.map((agenda, index) => (
            <Box
              key={index}
              sx={{
                overflow: "hidden",
              }}
            >
              <DoctorCard
                key={index}
                agenda={agenda}
                agendasLength={selectedDay.agendas?.length || 0}
                params={params}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 20 }}>
          <Typography
            sx={{ fontSize: 24, fontWeight: "bold", color: "primary.main" }}
          >
            No se encontraron resultados
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
