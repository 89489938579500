import React, { ReactNode, forwardRef } from "react";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import styled from "@emotion/styled";
import InputBase from "@mui/material/InputBase";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

interface CustomNativeSelectProps extends NativeSelectProps {
  children: ReactNode;
}

const CustomInput = styled(InputBase)(() => ({
  backgroundColor: "#57A3DF",
  fontSize: 16,
  color: "white",
  padding: 12,
  borderRadius: 8,
}));

export const CustomNativeSelect = forwardRef<
  HTMLSelectElement,
  CustomNativeSelectProps
>(({ children, ...props }, ref) => {
  return (
    <NativeSelect
      IconComponent={() => <KeyboardArrowDown />}
      input={<CustomInput />}
      ref={ref}
      {...props}
    >
      {children}
    </NativeSelect>
  );
});

export default CustomNativeSelect;
