import { SetStateAction, Dispatch } from "react";

type User = {
  _id: string;
} | null;

type Token = string | null;

export interface AuthContextInterface {
  user: User | null;
  token: Token | null;
  setUser: Dispatch<SetStateAction<User>>;
  setToken: Dispatch<SetStateAction<Token>>;
  // login: () => void;
  // logout: () => void;
}

export const authContextDefaults: AuthContextInterface = {
  user: null,
  token: null,
  setUser: () => {},
  setToken: () => {},
  // login: () => null,
  // logout: () => null,
};
