import { useState, useCallback } from "react";

import { Box, Typography, Button } from "@mui/material";
import { capitalize } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { getFullName } from "../utils/user";
import { useApi } from "../hooks/useApi";
import dayjs from "dayjs";

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  gender: string;
  type: string;
  avatar: { key: string };
}

interface Institution {
  _id: string;
  name: string;
}

interface MedicalSpecialty {
  _id: string;
  name: string;
}

interface Appointment {
  _id: string;
  startsAt: Date;
  doctor: User;
  institution: Institution;
  medicalSpecialty: MedicalSpecialty;
}

interface AppointmentCardProps {
  appointment: Appointment;
  cancelCallback: Function;
}

export const AppointmentCard = ({
  appointment,
  cancelCallback,
}: AppointmentCardProps) => {
  const api = useApi();
  const [isCancelling, setIsCancelling] = useState(false);

  const cancelAppointment = useCallback(
    async (id: Appointment["_id"]) => {
      try {
        setIsCancelling(true);

        const { data } = await api.delete(`/appointments/${id}`);

        if (data) {
          const cancelledAppointment = data[0]; // review this
          cancelCallback(cancelledAppointment);
        }
        console.log({ data });
      } catch (err) {
        console.log(err);
      } finally {
        setIsCancelling(false);
      }
    },
    [api, cancelCallback]
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "primary.light",
        paddingX: { md: 12, xs: 8 },
        paddingY: 6,
        borderRadius: 6,
      }}
      key={appointment._id}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{ fontSize: 15, color: "primary.dark" }}>
          {capitalize(dayjs(appointment.startsAt).format("dddd DD/MM"))} -{" "}
          {dayjs(appointment.startsAt).format("HH:mm A")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 18,
            color: "primary.dark",
          }}
        >
          {getFullName(appointment.doctor)}
        </Typography>
        <Typography sx={{ fontSize: 15, color: "primary.main" }}>
          {capitalize(appointment.institution.name)}
        </Typography>
        <Typography sx={{ fontSize: 15, color: "primary.main" }}>
          {capitalize(appointment.medicalSpecialty.name)}
        </Typography>
      </Box>
      <Button
        variant="contained"
        sx={{ backgroundColor: "error.main" }}
        onClick={() => cancelAppointment(appointment._id)}
        disabled={isCancelling}
      >
        <Typography sx={{ fontSize: 12, display: { xs: "none", md: "block" } }}>
          Cancelar turno
        </Typography>
        <CloseIcon sx={{ display: { md: "none" } }}></CloseIcon>
      </Button>
    </Box>
  );
};
