import * as React from "react";
import { forwardRef, useContext, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AppBar, IconButton, Paper } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { AuthContext } from "../contexts/AuthContextProvider";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../assets/logos/Logo";
import { useIsPwa } from "../hooks/useIsPwa";

type NavBarProps = {};

export const NavBar = forwardRef<HTMLDivElement, NavBarProps>((props, ref) => {
  const { setToken, token, setUser, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const isPwa = useIsPwa();
  const location = useLocation();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
  }, [setUser, setToken]);

  const login = useCallback(() => {
    navigate("/auth");
  }, [navigate]);

  return (
    <AppBar
      sx={{
        display: isPwa || location.pathname === "/auth" ? "none" : "flex",
        position: location.pathname === "/" ? "fiex" : "static",
        backgroundColor:
          location.pathname === "/" ? "transparent" : "primary.light",
        borderBottomLeftRadius: 25,
        borderBottomRightRadius: 25,
        padding: 2,
      }}
      elevation={0}
      ref={ref}
    >
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "transparent",
          borderRadius: 4,
          p: 2,
        }}
      >
        <Link to="/">
          <Logo />
        </Link>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 8,
          }}
        >
          <Button
            onClick={() => navigate("/appointments")}
            sx={{ color: "primary.dark" }}
          >
            Mis turnos
          </Button>
          {token && user ? (
            <Button variant="contained" onPointerDown={logout}>
              Cerrar sesión
            </Button>
          ) : (
            <Button variant="contained" onPointerDown={login}>
              Iniciar sesión
            </Button>
          )}
        </Box>

        <Box
          sx={{
            display: { xs: "flex", md: "none" },
          }}
        >
          <IconButton
            onClick={handleOpenUserMenu}
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={() => {
                navigate("appointments");
                handleCloseUserMenu();
              }}
            >
              Mis turnos
            </MenuItem>
            <MenuItem
              onClick={() => {
                logout();
                handleCloseUserMenu();
              }}
            >
              Cerrar sesión
            </MenuItem>
          </Menu>
        </Box>
      </Paper>
    </AppBar>
  );
});
