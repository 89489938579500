import { useEffect, useState, useCallback, useContext } from "react";
import { useApi } from "../hooks/useApi";
import { Box, CircularProgress, Typography, Stack } from "@mui/material";
import { AuthContext } from "../contexts/AuthContextProvider";
import { AppointmentCard } from "../components/AppointmentCard";

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  gender: string;
  type: string;
  avatar: { key: string };
}

interface Institution {
  _id: string;
  name: string;
}

interface MedicalSpecialty {
  _id: string;
  name: string;
}

interface Appointment {
  _id: string;
  startsAt: Date;
  doctor: User;
  institution: Institution;
  medicalSpecialty: MedicalSpecialty;
}

export const Appointments = () => {
  const api = useApi();
  const { user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const getUserAppointments = useCallback(async () => {
    if (user) {
      try {
        setIsLoading(true);
        const { data } = await api.get(`/appointments/`, {
          params: {
            patient: user._id,
            startsAt: { $lte: new Date() },
            status: "pending",
            populate: ["doctor", "institution", "medicalSpecialty"],
          },
        });

        setAppointments(data.docs);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [user, api]);

  useEffect(() => {
    getUserAppointments();
  }, [getUserAppointments]);

  const cancelAppointmentCallback = useCallback(
    (cancelledAppointment: Appointment) => {
      setAppointments((prevValues) =>
        prevValues.filter(
          (appointment) => appointment._id !== cancelledAppointment._id
        )
      );
    },
    []
  );

  return (
    <Box
      sx={{
        paddingX: { md: 24, xs: 8 },
        paddingY: { md: 12, xs: 6 },
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <Typography
        sx={{ fontSize: 20, fontWeight: "bold", color: "primary.dark" }}
      >
        Mis turnos
      </Typography>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
          <CircularProgress />
        </Box>
      ) : !appointments.length ? (
        <Typography
          sx={{
            marginTop: 20,
            textAlign: "center",
            fontWeight: "bold",
            color: "primary.main",
            fontSize: 20,
          }}
        >
          No se encontraron turnos
        </Typography>
      ) : (
        <Stack spacing={2}>
          {appointments.map((appointment) => (
            <AppointmentCard
              key={appointment._id}
              appointment={appointment}
              cancelCallback={cancelAppointmentCallback}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};
