import React, { useContext, useEffect, useState, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { ThemeProvider } from "@emotion/react";
// import useScrollTrigger from "@mui/material/useScrollTrigger";
import theme from "./themes/theme";
import { Home } from "./pages/Home";
import { Slots } from "./pages/Slots";
import { About } from "./pages/About";
import { Auth } from "./pages/Auth";
import { Appointments } from "./pages/Appointments";
import { SlotConfirmation } from "./pages/SlotConfirmation";
import { NavBar } from "./components/NavBar";
import { AuthContext, AuthProvider } from "./contexts/AuthContextProvider";
import { SnackbarProvider } from "./contexts/SnackbarContextProvider";

// interface Props {
//   window?: () => Window;
//   children: React.ReactElement;
// }

// function ElevationScroll(props: Props) {
//   const { children, window } = props;
//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: 0,
//     target: window ? window() : undefined,
//   });

//   return React.cloneElement(children, {
//     elevation: trigger ? 4 : 0,
//   });
// }

const PrivateRoutes = () => {
  const { setToken, token } = useContext(AuthContext);
  const location = useLocation();

  const unauthorized = token
    ? (jwtDecode(token)?.exp || 0) < dayjs().unix()
    : true;

  if (unauthorized) {
    setToken(null);
    return (
      <Navigate
        to={`/auth`}
        replace
        state={{ redirect: `${location.pathname}${location.search}` }}
      />
    );
  }
  return <Outlet />;
};

function App() {
  const appBarRef = useRef<HTMLDivElement>(null);
  const [, setAppBarHeight] = useState(0);

  useEffect(() => {
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.clientHeight);
    }
  }, [appBarRef.current?.clientHeight]);

  return (
    <ThemeProvider theme={theme}>
      {/* <GlobalStyles
        styles={{
          body: {
            background: "linear-gradient(to bottom, #57A3DF, #D0E6F7)",
            height: "100%",
            margin: 0,
            padding: 0,
          },
          html: {
            minHeight: "100%",
          },
          "#root": {
            height: "100%",
          },
        }}
      /> */}
      <AuthProvider>
        <SnackbarProvider>
          <Router>
            {/* <ElevationScroll> */}
            <NavBar ref={appBarRef} />
            {/* </ElevationScroll> */}
            {/* <Box sx={{ height: { xs: appBarHeight, md: 0 } }} /> */}
            <Routes>
              <Route path="/auth" element={<Auth />} />
              <Route element={<PrivateRoutes />}>
                <Route path="/" element={<Home />} />
                <Route path="/slots" element={<Slots />} />
                <Route path="/about" element={<About />} />
                <Route
                  path="/slot/:slotId/:medicalSpecialtyId/:attentionType"
                  element={<SlotConfirmation />}
                />
                <Route path="/appointments" element={<Appointments />} />
              </Route>
            </Routes>
          </Router>
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
