import { createContext, useState, ReactNode, useEffect } from "react";
import { AuthContextInterface, authContextDefaults } from "./AuthContext";

export const AuthContext =
  createContext<AuthContextInterface>(authContextDefaults);

export const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const [user, setUser] = useState(() => {
    try {
      const storedValue = window.localStorage.getItem("user");
      return storedValue ? JSON.parse(storedValue) : authContextDefaults.user;
    } catch (error) {
      return authContextDefaults.user;
    }
  });

  const [token, setToken] = useState(() => {
    try {
      const storedValue = window.localStorage.getItem("token");
      return storedValue ? JSON.parse(storedValue) : authContextDefaults.token;
    } catch (error) {
      return authContextDefaults.token;
    }
  });

  useEffect(() => {
    try {
      if (user) {
        window.localStorage.setItem("user", JSON.stringify(user));
      } else {
        window.localStorage.removeItem("user");
      }
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  useEffect(() => {
    try {
      if (token) {
        window.localStorage.setItem("token", JSON.stringify(token));
      } else {
        window.localStorage.removeItem("token");
      }
    } catch (error) {
      console.error(error);
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};
