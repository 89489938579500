export enum SnackbarModes {
  error = "error",
  success = "success",
  warning = "warning",
  info = "info",
}

export interface SnackbarOptions {
  mode: SnackbarModes;
}

export interface SnackbarInterface {
  showSnackbar: (msg: string, options?: SnackbarOptions) => void;
}

export const SnackbarDefaultOptions: SnackbarOptions = {
  mode: SnackbarModes.info,
};

export const SnackbarDefaults: SnackbarInterface = {
  showSnackbar: (msg, SnackbarDefaultOptions) => {},
};
