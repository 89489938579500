import React from "react";
import defaultLogo from "./default.svg";
import ccrLogo from "./ccr.svg";

const logoMap: { [key: string]: string } = {
  default: defaultLogo,
  ccr: ccrLogo,
};

interface LogoProps {
  height?: number;
}

const Logo: React.FC<LogoProps> = ({ height }) => {
  const tenant = process.env.REACT_APP_TENANT as string;
  const logoSrc = logoMap[tenant] || defaultLogo;
  return <img src={logoSrc} alt={`${tenant} logo`} height={height || 30} />;
};

export default Logo;
