import { createTheme } from "@mui/material/styles";
import { esES } from "@mui/x-date-pickers/locales";

let theme = createTheme(
  {
    spacing: (factor: number) => `${0.25 * factor}rem`,
    palette: {
      primary: {
        dark: "#22316c",
        main: "#57A3DF",
        light: "#D0E6F7",
      },
      common: {
        white: "#FFFFFC",
        black: "#01022A",
      },
      background: {
        default: "red",
      },
    },
  },
  esES
);

theme = createTheme(theme, {
  spacing: (factor: number) => `${0.25 * factor}rem`,
  typography: {
    fontFamily: "Archivo",
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "h1",
          },
          style: {
            fontWeight: 800,
            fontSize: 50,
            color: theme.palette.primary.dark,
          },
        },
        {
          props: {
            variant: "h3",
          },
          style: {
            fontWeight: 500,
            fontSize: 20,
            color: theme.palette.primary.dark,
          },
        },
      ],
    },
    MuiInput: {
      variants: [
        {
          props: {
            variant: "filled",
          },
          style: {
            backgroundColor: `${theme.palette.primary.main} !important`,
            "&:focused": {
              backgroundColor: `${theme.palette.primary.main} !important`,
            },
          },
        },
      ],
    },
    MuiSelect: {
      defaultProps: {
        inputProps: {
          sx: {
            // backgroundColor: `${theme.palette.primary.main} !important`,
            // color: "white",
            // color: "white",
            // "&::placeholder": {
            //   color: "white",
            //   opacity: 1,
            // },
            // "&:focus": {
            //   backgroundColor: theme.palette.primary.main,
            // },
            // "&:hover": {
            //   backgroundColor: theme.palette.primary.main,
            // },
          },
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
          padding: 12,
          borderRadius: 8,
          input: {
            color: "white",
          },
          placeholder: {
            color: "white",
          },
        },
      },
    },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: {
    //       color: "white",
    //       "&:focused": {
    //         color: "white",
    //       },
    //     },
    //   },
    // },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          borderRadius: 8,
          color: "white",
        },
      },
      variants: [
        {
          props: {
            variant: "contained",
          },
          style: {
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      ],
    },
    MuiNativeSelect: {
      defaultProps: {
        inputProps: {
          style: {
            input: {
              color: "white",
            },
            placeholder: {
              color: "white",
              textAlign: "center",
            },
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "&": {
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          body: {
            backgroundColor: "red",
          },
          html: {
            height: "100%",
          },
          "#root": {
            height: "100%",
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "primary.main !important",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          },
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "primary.main !important",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.main",
            },
          },
        },
      },
    },
  },
});

export default theme;
